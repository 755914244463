* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

body,
button {
  font-family: 'Source Sans Pro', sans-serif;
}